'use client'
import { useContext, useEffect, useState } from 'react'

import MobileMenu from './MobileMenu/MobileMenu'
import DesktopMenu from './DesktopMenu/DesktopMenu'
import { getFlexcardURL, getIsDarkBG } from './Header.functions'

import styles from './Header.module.css'
import { usePathname } from 'next/navigation'
import AppContext from '@/context/appContext'
import useGetCountries from 'hooks/Countries/useGetCountries'

type HeaderTypes = {
  transparent?: boolean
  locale: string
  page?: string
  countryCode?: string
}

export default function Header({
  transparent = false,
  locale,
  page = 'unkown',
}: HeaderTypes) {
  const pathname = usePathname()
  const [sticky, setSticky] = useState('')
  const { location } = useContext(AppContext)
  const { countriesData: countries } = useGetCountries()

  const isCMS = () => {
    const urlSegments = pathname.split('/')
    if (urlSegments[1] === 'cms') {
      return true
    }
    return false
  }

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  }, [])

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY
    const stickyClass = scrollTop >= 1 ? 'IsSticky' : ''
    setSticky(stickyClass)
  }

  return (
    <>
      <section
        className={`${styles.HeaderDesktop} ${
          transparent ? styles.HeaderTransparent : ''
        } ${styles[sticky]}`}
      >
        <DesktopMenu
          page={isCMS() ? 'cms' : page}
          locale={locale}
          sticky={sticky}
          flexcardURL={
            countries && location
              ? getFlexcardURL(
                  location?.countryCode?.toLowerCase(),
                  locale,
                  countries
                )
              : 'https://kayakomat.shop/'
          }
          isDark={getIsDarkBG(page)}
        />
      </section>
      <section
        className={`${styles.HeaderMobile} ${
          transparent ? styles.HeaderTransparent : ''
        } ${styles[sticky]}`}
      >
        <MobileMenu
          page={isCMS() ? 'cms' : page}
          locale={locale}
          sticky={sticky}
        />
      </section>
    </>
  )
}
