import styles from './Container.module.css'

type ContainerTypes = {
  children: string | JSX.Element | JSX.Element[]
  invert?: boolean
  padding?: 'medium' | 'small'
  src?: string
  tint?: number
}

export default function Container({
  children,
  invert,
  padding = 'medium',
  src,
  tint,
}: ContainerTypes) {
  return (
    <section
      className={`${styles.Container} ${styles[`Container-padding--${padding}`]}`}
      style={{
        backgroundColor: invert && 'black',
        backgroundImage: src && `url(${src})`,
        width: src && '100%',
        maxWidth: src && '100vw',
        backgroundSize: src && 'cover',
        backgroundPosition: src && 'center',
      }}
    >
      {children}
    </section>
  )
}
