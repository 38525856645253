import { createContext, Dispatch, SetStateAction } from 'react'

export type LocationTypes = {
  city: string
  country: string
  countryId?: string
  countryCode: string
  lat: number
  lng: number
  timestamp: number
  zoom: number
}

export type MapCoordTypes = {
  lat: number
  lng: number
  zoom: number
}

type AppContextTypes = {
  mapCoords: MapCoordTypes
  setMapCoords: Dispatch<SetStateAction<MapCoordTypes>>
  location: LocationTypes
  setLocation: Dispatch<SetStateAction<LocationTypes>>
  sessionId: string
  setSessionId: Dispatch<SetStateAction<string>>
}

const initialMapCoords: MapCoordTypes = {
  lng: null,
  lat: null,
  zoom: null,
}

const initialLocation: LocationTypes = {
  city: '',
  country: '',
  countryCode: '',
  lat: null,
  lng: null,
  timestamp: null,
  zoom: null,
}

const AppContext = createContext<AppContextTypes>({
  mapCoords: initialMapCoords,
  setMapCoords: () => {},
  location: initialLocation,
  setLocation: () => {},
  sessionId: '',
  setSessionId: () => {},
})

export default AppContext
