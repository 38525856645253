import styles from './Checkbox.module.css'

type CheckboxTypes = {
  label: string
  value?: boolean
  disabled?: boolean
  onChange?: (arg0: any) => void
}

export default function Checkbox({
  value,
  label,
  disabled,
  onChange,
}: CheckboxTypes) {
  return (
    <label className={styles.container}>
      {label}
      <input
        type="checkbox"
        checked={value}
        onChange={(e) => {
          onChange(e.target.checked)
        }}
        disabled={disabled}
      />
      <span className={styles.checkmark}></span>
    </label>
  )
}
