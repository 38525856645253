import styles from './Heading.module.css'

type H3Types = {
  invert?: boolean
  children: string | JSX.Element
  textAlign?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'match-parent'
}

export default function H3({ children, textAlign = 'left', invert }: H3Types) {
  return (
    <h3
      className={styles.h3}
      style={{ textAlign, color: invert && '#FFF' }}
      dangerouslySetInnerHTML={{ __html: children }}
    ></h3>
  )
}
