const gtmEvent = (eventName, rest) => {
    try {
        console.log("event name:", eventName, "rest:", rest)
        window.dataLayer?.push({
            event: eventName,
            ...rest,
        });
    } catch {
        console.log("Failed to push GTM event: " + eventName)
    }
}

const gtmBookingEventWrapper = (eventName, rest) => {
    // looker cannot take kebab case
    if (eventName === 'booking_flow_after-payment') {
        eventName = 'booking_flow_afterPayment'
    }
    gtmEvent(eventName, rest)
}

export { gtmEvent, gtmBookingEventWrapper }