'use client'

import { getSessionId } from '@/helper/getSessionId'
import getLocation from '@/helper/ipLocation'
import AppContext, { LocationTypes, MapCoordTypes } from 'context/appContext'
import { useEffect, useMemo, useState } from 'react'

export function Providers({ children }: { children: React.ReactNode }) {
  const [location, setLocation] = useState<LocationTypes>()
  const [mapCoords, setMapCoords] = useState<MapCoordTypes>()
  const [sessionId, setSessionId] = useState(getSessionId())

  useEffect(() => {
    const getLocationIP = async () => {
      const locationIP = await getLocation()
      setLocation(locationIP)
      return locationIP
    }

    if (!location?.countryCode) {
      getLocationIP()
    }
  }, [location, setLocation])

  const appValue = useMemo(
    () => ({
      mapCoords,
      setMapCoords,
      location,
      setLocation,
      sessionId,
      setSessionId,
    }),
    [mapCoords, setMapCoords, location, setLocation, sessionId, setSessionId]
  )

  return <AppContext.Provider value={appValue}>{children}</AppContext.Provider>
}
