'use client'

import { useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'

import styles from './Translation.module.css'
import { translationList } from '../Header.functions'

export default function TranslationsCMS({ locale, isSticky = false }) {
  const [menuActive, setMenuActive] = useState(false)
  const router = useRouter()
  const pathname = usePathname()

  const getCurrentLocale = () => {
    const currentTranslation = translationList.find((t) => t.locale === locale)
    return (
      <div onClick={() => setMenuActive(!menuActive)}>
        {currentTranslation?.img}
      </div>
    )
  }

  const newLocalePath = (locale: string, url: string): string => {
    const urlSegments = url.split('/')
    if (urlSegments[1] === 'cms') {
      return `/${urlSegments[1]}/${locale}/${urlSegments[3]}`
    }
    return
  }

  const changeLocale = (nextLocale) => {
    document.cookie = `NEXT_LOCALE=${nextLocale}; expires=Fri, 31 Dec 2030 23:59:59 GMT`
    router.push(newLocalePath(nextLocale, pathname))
  }

  return (
    <div className={styles.selectLocaleContainer}>
      <div className={styles.currentLocale}>{getCurrentLocale()}</div>
      {menuActive && (
        <div
          className={`${styles.selectLocale} ${
            isSticky === false ? styles.selectLocaleNotSticky : 'asd'
          }`}
        >
          {translationList.map((t, i) => (
            <div
              key={i}
              className={styles.localeItem}
              onClick={() => {
                changeLocale(t.locale)
                setMenuActive(!menuActive)
              }}
            >
              {t.img}
              <span>{t.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}
