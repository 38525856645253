'use client'

import Link from 'next/link'
import styles from './Button.module.css'

type ButtonTypes = {
  url?: string
  style?:
    | 'default'
    | 'line-light'
    | 'orange'
    | 'success'
    | 'line-light-bg'
    | 'disabled'
    | 'error'
    | 'minimal'
    | 'cookie'
  fitContent?: boolean
  skipNextLink?: boolean
  onClick?: any
  children?: string | JSX.Element
  size?: 'small' | 'medium'
  type?: 'submit' | 'button' | 'reset'
  disabled?: boolean
  invert?: boolean
}

export default function Button({
  children,
  url,
  onClick,
  skipNextLink = false,
  size,
  fitContent = false,
  style = 'default',
  type,
  disabled,
  invert,
}: ButtonTypes) {
  const className = `${styles.Button} ${styles[`style-${style}`]} ${
    size ? styles[size] : styles.medium
  } ${fitContent ? styles.fitContent : ''} ${invert ? styles.invert : ''}`

  const buttonBody = (
    <button
      type={type || 'button'}
      onClick={() => {
        onClick && onClick()
      }}
      className={className}
      disabled={disabled}
    >
      <span>{children}</span>
    </button>
  )

  if (skipNextLink && url) {
    return <a href={url}>{buttonBody}</a>
  }

  return url ? <a href={url}>{buttonBody}</a> : buttonBody
}
