import '../styles/globals.css'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { gtmEvent } from '../helper/gtm'
import { useAnalytics } from '@happykit/analytics'
import Header from '@layout/Header/Header'
import Footer from '@layout/Footer/Footer'
import CookieBanner from '@layout/CookiesBanner/CookiesBanner'
import HotJarWrapper from '@layout/HotJarWrapper/HotJarWrapper'
import { SpeedInsights } from '@vercel/speed-insights/next'

import '/node_modules/flag-icons/css/flag-icons.min.css'
import { Providers } from '@/context/Providers'

export interface SharedPageProps {
  draftMode: boolean
  token: string
}

function MyApp({ Component, pageProps }) {
  const [statisticsCookie, setStatisticsCookie] = useState('denied')

  const router = useRouter()

  // Track page views
  useAnalytics({ publicKey: 'analytics_pub_56f91b7ab4' })

  useEffect(() => {
    const mainDataLayer = {
      pageTypeName: pageProps.page || null,
      url: router.pathname,
      language: pageProps.locale || null,
      supplier: pageProps.supplier || null,
      productSettings: pageProps.productSettings || null,
      productPriceRates: pageProps.productPriceRates || null,
      products: pageProps.products || null,
      otherInfo: pageProps || null,
    }

    setStatisticsCookie(
      window.localStorage.getItem('KAYAKOMAT_COOKIE_CONSENT_STATISTICS')
    )

    gtmEvent('VirtualPageView', mainDataLayer)
  }, [pageProps])

  return (
    <>
      <Script id="google-tag-manager" strategy="afterInteractive">
        {`
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}')
        `}
      </Script>
      <Providers>
        {statisticsCookie === 'granted' && <HotJarWrapper />}
        <Header locale={pageProps.locale} page={pageProps.page} />
        <Component {...pageProps} />
        <SpeedInsights />
        <Footer
          locale={pageProps.locale}
          isAboutCMS={pageProps.flags?.aboutPageCMS}
          faqFlag={pageProps.flags?.cmsFAQs}
        />
        <CookieBanner locale={pageProps.locale} />
      </Providers>
    </>
  )
}

export default MyApp
