module.exports = {
    "usefullLinks": {
        sv: 'ANVÄNDBART',
        en: 'USEFUL LINKS',
        de: 'NÜTZLICHE LINKS',
        es: 'ENLACES ÚTILES',
        dk: 'NYTTIGE LINKS',
        fr: 'LIENS UTILES'
    }, 
    "companyLinks": {
        sv: 'FÖRETAG',
        en: 'COMPANY',
        de: 'UNTERNEHMEN',
        es: 'EMPRESA',
        dk: 'VIRKSOMHED',
        fr: 'ENTREPRISE'
    },
    "saftey": {
        sv: 'SÄKERHET',
        en: 'SAFETY',
        de: 'SICHERHEIT',
        es: 'SEGURIDAD',
        no: '[no] Instruktioner',
        dk: 'SIKKERHED',
        fr: 'SÉCURITÉ'
    },
    "faq": {
        sv: 'FAQ',
        en: 'FAQ',
        de: 'FAQ',
        es: 'FAQ',
        dk: 'FAQ',
        fr: 'FAQ'
    },
    "about": {
        sv: 'Om oss',
        en: 'About',
        de: 'Über uns',
        es: 'Sobre nosotros',
        dk: 'Om os',
        fr: 'À propos de nous' 
    },
    "prices": {
        sv: 'Priser',
        en: 'Prices',
        de: 'Preise',
        es: 'Precios',
        dk: 'Priser',
        fr: 'Prix' 
    },
    "contact": {
        sv: 'Kontakta',
        en: 'Contact',
        de: 'Kontakt',
        es: 'Contacto',
        dk: 'Kontakt',
        fr: 'Contact' 
    },
    "workWithUs": {
        sv: 'Jobb',
        en: 'Jobs',
        de: 'Stellenangebote',
        es: 'Empleo',
        dk: 'Jobs',
        fr: 'Emplois'
    },
    "press": {
        sv: 'Press',
        en: 'Press',
        de: 'Presse',
        es: 'Prensa',
        dk: 'Press',
        fr: 'Presse'
    },
    "terms": {
        sv: 'Villkor',
        en: 'Terms of use',
        de: 'Nutzungsbedingungen',
        es: 'Condiciones de uso',
        dk: 'Vilkår for brug',
        fr: 'Conditions d\'utilisation'
    },
    "privacy": {
        sv: 'Sekretesspolicy',
        en: 'Privacy policy',
        de: 'Datenschutzbestimmungen',
        es: 'Política de privacidad',
        dk: 'Privatlivspolitik',
        fr: 'Politique de confidentialité'
    },
    "followUs": {
        sv: 'FÖLJ OSS',
        en: 'FOLLOW US',
        de: 'FOLLOW US',
        es: 'SÍGUENOS',
        dk: 'FØLG OS',
        fr: 'SUIVEZ-NOUS'
    },
    "startKayakomatLabel": {
        sv: 'STARTA KAYAKOMAT',
        en: 'START KAYAKOMAT',
        de: 'KAYAKOMAT STARTEN',
        es: 'INICIAR KAYAKOMAT',
        dk: 'STARTE KAYAKOMAT',
        fr: 'DÉMARRER KAYAKOMAT'
    },
}