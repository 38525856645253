import Link from 'next/link'
import Container from '@layout/Container/Container'

import Translations from '../Translation/Translation'
import TranslationsCMS from '../Translation/TranslationCMS'

import MobileMenuNav from './Navigation'
import styles from '../Header.module.css'

export default function MobileMenu({ locale, page = 'unkown', sticky }) {
  return (
    <Container>
      <section className={styles.HeaderInner}>
        <section className={styles.HeaderLogo}>
          <Link href={`/${locale}`}>
            <img src="/icons/logo2.svg" alt={'Kayakomat logo - mobile'} />
          </Link>
        </section>
        <section className={styles.HeaderNav}>
          {page === 'cms' ? (
            <TranslationsCMS
              locale={locale}
              isSticky={sticky === 'IsSticky' ? true : false}
            />
          ) : (
            <Translations
              locale={locale}
              isSticky={sticky === 'IsSticky' ? true : false}
            />
          )}
          <MobileMenuNav locale={locale} page={page} />
        </section>
      </section>
    </Container>
  )
}
