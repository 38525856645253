import axios from 'axios'

axios.defaults.withCredentials = false

type PropsTypes = {
  url: string
  sessionId: string
  params?: {}
}

export const getRequest = (props: PropsTypes) => {
  return axios.get(props.url, {
    params: { ...props.params },
    headers: { 'X-Session-Id': props.sessionId },
  })
}

export const deleteRequest = (props: PropsTypes) => {
  return axios.delete(props.url, {
    params: { ...props.params },
    headers: { 'X-Session-Id': props.sessionId },
  })
}

export const postRequest = (props: PropsTypes, { arg }: { arg: any }) => {
  return axios.post(props.url, arg, {
    params: { ...props.params },
    headers: { 'X-Session-Id': props.sessionId },
  })
}

export const putRequest = (props: PropsTypes, { arg }: { arg: any }) => {
  return axios.put(props.url, arg, {
    params: { ...props.params },
    headers: { 'X-Session-Id': props.sessionId },
  })
}
