module.exports = {
  findKayakomatButton: {
    sv: 'HITTA EN KAYAKOMAT',
    en: 'FIND A KAYAKOMAT',
    de: 'FINDE EINE KAYAKOMAT STATION',
    es: 'ENCONTRAR UN KAYAKOMAT',
    dk: 'FIND EN KAYAKOMAT',
    fr: 'TROUVER UN KAYAKOMAT',
  },
  searchButton: {
    sv: 'SÖK',
    en: 'SEARCH',
    de: 'SUCHEN',
    es: 'BÚSQUEDA',
    dk: 'Søg',
    fr: 'RECHERCHE',
  },
  chooseStationButton: {
    sv: 'VÄLJ KAYAKOMAT',
    en: 'CHOOSE KAYAKOMAT',
    de: 'KAYAKOMAT WÄHLEN',
    es: 'ELIGE KAYAKOMAT',
    dk: 'Vælg KAYAKOMAT',
    fr: 'CHOISISSEZ KAYAKOMAT',
  },
  inactiveStationButton: {
    sv: 'BOKNING EJ TILLGÄNGLIG',
    en: 'BOOKING UNAVAILABLE',
    de: 'BUCHUNG NICHT VERFÜGBAR',
    es: 'RESERVA NO DISPONIBLE',
    dk: 'BESTILLING ER IKKE TILGÆNGELIG',
    fr: 'RÉSERVATION INDISPONIBLE',
  },
  bookNowButton: {
    sv: 'Boka Nu',
    en: 'Book Now',
    de: 'Jetzt Buchen',
    es: 'Reservar',
    no: '[no] Boka',
    dk: 'Book nu',
    fr: 'Réservez Maintenant',
  },
  makeABookingButton: {
    sv: 'BOKA',
    en: 'MAKE A BOOKING',
    de: 'LAV EN BOOKING',
    es: 'REALIZAR UNA RESERVA',
    dk: 'BUCHUNG VORNEHMEN',
    fr: 'FAIRE UNE RÉSERVATION',
  },
  shopSeasonalPassButton: {
    sv: 'KÖP SÄSONGSKORT',
    en: 'SHOP SEASON PASS',
    de: 'SAISONKARTE KAUFEN',
    es: 'COMPRAR PASE DE TEMPORADA',
    dk: 'AUSRÜSTUNG SHOPPEN',
    fr: "ACHETER L'ABONNEMENT DE SAISON",
  },
  shopGearButton: {
    sv: 'HANDLA UTRUSTNING',
    en: 'SHOP GEAR',
    de: 'AUSRÜSTUNG KAUFEN',
    es: 'COMPRAR EQUIPO',
    dk: 'KØB GREJ',
    fr: 'ACHETER DU MATÉRIEL',
  },
  startKayakomatButton: {
    sv: 'HANDLA UTRUSTNING',
    en: 'SHOP GEAR',
    de: 'AUSRÜSTUNG KAUFEN',
    es: 'COMPRAR EQUIPO',
    dk: 'KØB GREJ',
    fr: 'ACHETER DU MATÉRIEL',
  },
  buyDiscount: {
    sv: 'KÖP PRESENTKORT/KLIPPKORT',
    en: 'BUY GIFT/SEASONAL PASS',
    de: 'GUTSCHEIN/FLEX-KARTE',
    es: 'DESCUENTOS Y TARJETAS REGALO',
    dk: 'KØB GAVE/SÆSONKORT',
    fr: 'CARTE DE RÉDUCTION/CADEAU',
  },
  buyDiscountScroll: {
    sv: 'KÖP PRESENTKORT',
    en: 'BUY GIFT CARD',
    de: 'GUTSCHEIN KAUFEN',
    es: 'TARJETA REGALO',
    dk: 'KØB GAVEKORT',
    fr: 'CARTE DE CADEAU',
  },
}
