import Link from 'next/link'

import Column from '@layout/Column/Column'
import ColumnContainer from '@layout/Column/Container/Container'

import styles from './Top.module.css'

export default function FooterTop({
  T,
  locale,
  isAboutCMS = false,
  faqFlag = false,
}) {
  return (
    <section className={styles.FooterTop}>
      <Column width={66}>
        <div className={styles.FooterLogo}>
          <img src="/icons/logo-white.png" />
        </div>
      </Column>
      <Column width={33}>
        <ColumnContainer gap={35}>
          <Column width={50}>
            <p className={styles.FooterLinkHeading}>{T.usefullLinks[locale]}</p>
            <div className={styles.FooterLink}>
              <Link href={`/${locale}/start-kayakomat-franchise`}>
                <div>{T.startKayakomatLabel[locale]}</div>
              </Link>
            </div>
            <div className={styles.FooterLink}>
              <Link href={`/${locale}/safety`}>
                <div>{T.saftey[locale]}</div>
              </Link>
            </div>
            <div className={styles.FooterLink}>
              <a href={faqFlag ? `/cms/${locale}/faq` : `/${locale}/faq`}>
                <div>{T.faq[locale]}</div>
              </a>
            </div>
            <div className={styles.FooterLink}>
              <a href={`/cms/${locale}/prices`}>
                <div>{T.prices[locale]}</div>
              </a>
            </div>
            <div className={styles.FooterLink}>
              <a href={`/${locale}/contact`}>
                <div>{T.contact[locale]}</div>
              </a>
            </div>
          </Column>
          <Column width={50}>
            <p className={styles.FooterLinkHeading}>{T.companyLinks[locale]}</p>
            <div className={styles.FooterLink}>
              <a
                href={isAboutCMS ? `/cms/${locale}/about` : `/${locale}/about`}
              >
                <div>{T.about[locale]}</div>
              </a>
            </div>
            <div className={styles.FooterLink}>
              <Link href={`https://www.point65.com/`}>
                <div>Point 65 Sweden</div>
              </Link>
            </div>
            <div className={styles.FooterLink}>
              <Link href={`https://boblbee.com/`}>
                <div>Boblbee</div>
              </Link>
            </div>
            <div className={styles.FooterLink}>
              <Link href={`https://www.linkedin.com/company/kayakomat/jobs/`}>
                <div>{T.workWithUs[locale]}</div>
              </Link>
            </div>
            <div className={styles.FooterLink}>
              <Link href={`https://kayakomat.info/press`}>
                <div>{T.press[locale]}</div>
              </Link>
            </div>
          </Column>
        </ColumnContainer>
      </Column>
    </section>
  )
}
