import Link from 'next/link'

import Button from '@library/Button/Button'
import Container from '@layout/Container/Container'
import buttonT from '@/translations/v2/ui/buttons.js'
import footerT from '@/translations/v2/reusable/footer'

import styles from '../Header.module.css'
import Translations from '../Translation/Translation'
import TranslationsCMS from '../Translation/TranslationCMS'
import { noBackgroundImagePages } from '../Header.functions'

export default function DesktopMenu({
  locale,
  page = 'unkown',
  flexcardURL,
  sticky,
  isDark = false,
}) {
  return (
    <Container>
      <section className={styles.HeaderInner}>
        <section className={styles.HeaderLogo}>
          <Link href={`/${locale}`} passHref>
            <img
              src={isDark || !!sticky ? '/logo-black.png' : '/logo-white.svg'}
              alt={'Kayakomat logo'}
            />
          </Link>
        </section>
        <section className={styles.HeaderNav}>
          {page === 'cms' ? (
            <TranslationsCMS
              locale={locale}
              isSticky={sticky === 'IsSticky' ? true : false}
            />
          ) : (
            <Translations
              locale={locale}
              isSticky={sticky === 'IsSticky' ? true : false}
            />
          )}
          <Button
            invert={noBackgroundImagePages.includes(page) || !!sticky}
            size={sticky === 'IsSticky' ? 'small' : 'medium'}
            skipNextLink={true}
            style="minimal"
            url={flexcardURL}
          >
            {sticky
              ? buttonT.buyDiscountScroll[locale]
              : buttonT.buyDiscount[locale]}
          </Button>
          {page !== 'start' && (
            <Button
              invert={noBackgroundImagePages.includes(page) || !!sticky}
              size={sticky === 'IsSticky' ? 'small' : 'medium'}
              style="minimal"
              url={`/${locale}/start-kayakomat-franchise`}
            >
              {footerT.startKayakomatLabel[locale]}
            </Button>
          )}

          <Button
            invert={noBackgroundImagePages.includes(page) || !!sticky}
            size={sticky === 'IsSticky' ? 'small' : 'medium'}
            style="minimal"
            url={`/${locale}`}
          >
            {buttonT.findKayakomatButton[locale]}
          </Button>
        </section>
      </section>
    </Container>
  )
}
