import axios from 'axios'
import getCookie from 'helper/getCookie'

const getLocationFromIP = async () => {
  const consent = getCookie('KAYAKOMAT_COOKIE_CONSENT_PREFERENCES')
  try {
    const ipRes = await axios.get(
      `${process.env.NEXT_PUBLIC_APP_API}/web/iplocation`
    )
    if (ipRes?.data?.msg === 'FOUND' && consent === 'granted') {
      const location = {
        city: ipRes?.data?.city,
        country: ipRes?.data?.country,
        countryCode: ipRes?.data?.countryCode,
        lat: ipRes?.data?.lat,
        lng: ipRes?.data?.lng,
      }
      window.localStorage.setItem(
        'location',
        JSON.stringify({
          ...location,
          timestamp: Date.now() + 3600000,
        })
      )
      return location
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

const getLocation = async () => {
  const location = JSON.parse(window.localStorage.getItem('location'))
  if (location && location.timestamp > Date.now()) {
    return location
  } else {
    const location = await getLocationFromIP()
    return location
  }
}

export default getLocation
