import styles from './Column.module.css'

type GapTypes = 10 | 15 | 25 | 35 | 50 | 120
type AlignTypes = 'center' | 'start' | 'end'
type WidthTypes = 25 | 33 | 50 | 66 | 75 | 100
type WrapTypes = 'nowrap' | 'wrap' | 'wrap-reverse'

export type ColumnOptionsTypes = {
  grow?: boolean
  vAlign?: AlignTypes
  hAlign?: AlignTypes
  width?: WidthTypes
}

export type ContainerOptionsTypes = {
  gap?: GapTypes
  wrap?: WrapTypes
  vAlign?: AlignTypes
  hAlign?: AlignTypes
  margin?: string
  padding?: string
}

const getGap = (gap: GapTypes) => gap && styles[`Col-gap--${gap}`]
const getWrap = (wrap: WrapTypes) => wrap && styles[`Col-wrap--${wrap}`]
const getGrow = (grow: boolean) => grow && styles['Col-grow']
const getVAlign = (align: AlignTypes) => align && styles[`V-Align--${align}`]
const getHAlign = (align: AlignTypes) => align && styles[`H-Align--${align}`]
const getWidth = (width: WidthTypes) =>
  width ? styles[`Col-${width}`] : styles[`Col-100`]

export const getContainerStyles = (options: ContainerOptionsTypes) => {
  return [
    styles.Columns,
    getVAlign(options.vAlign),
    getHAlign(options.hAlign),
    getGap(options.gap),
    getWrap(options.wrap),
  ].join(' ')
}

export const getColumnStyles = (options: ColumnOptionsTypes) => {
  return [
    getWidth(options.width),
    getVAlign(options.vAlign),
    getHAlign(options.hAlign),
    getGrow(options.grow),
  ].join(' ')
}
