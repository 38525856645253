type CountryTypes = {
  name: string
  shortcode?: string
  currency?: string
  taxRate?: number
  newsletterSignup?: string
  terms?: string
  privacy?: string
  mailchimpListId?: string
  stripePaymentMethods?: string[]
  flexcard: {
    sv: string
    en: string
    de: string
    fr: string
    dk: string
    es: string
  }
}

export const noBackgroundImagePages = ['safety', 'location', 'review']
export const getIsDarkBG = (page) => noBackgroundImagePages.includes(page)

export const translationList = [
  {
    locale: 'sv',
    img: <span className="fi fi-se fis"></span>,
    label: 'Swedish',
  },
  {
    locale: 'dk',
    img: <span className="fi fi-dk fis"></span>,
    label: 'Danish',
  },
  {
    locale: 'de',
    img: <span className="fi fi-de fis"></span>,
    label: 'German',
  },
  {
    locale: 'en',
    img: <span className="fi fi-gb fis"></span>,
    label: 'English',
  },
  {
    locale: 'es',
    img: <span className="fi fi-es fis"></span>,
    label: 'Spanish',
  },
  {
    locale: 'fr',
    img: <span className="fi fi-fr fis"></span>,
    label: 'French',
  },
]

export const getFlexcardURL = (
  countryCode: string,
  languageCode: string,
  countries: CountryTypes[]
) => {
  const country = countries?.find(
    (country) => country.shortcode === countryCode.toLowerCase()
  )
  return country?.flexcard[languageCode] || 'https://kayakomat.shop/'
}
