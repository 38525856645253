import Link from 'next/link'
import Icon from '@library/Icons/Icons'
import styles from './Bottom.module.css'

export default function FooterBottom({ T, locale, currentCountry }) {
  const isImpressumVisible: boolean =
    currentCountry === 'at' ||
    currentCountry === 'ch' ||
    currentCountry === 'de'

  return (
    <section className={styles.FooterBottom}>
      <div className={styles.FooterBottomInner}>
        <div className={styles.FooterSocialContainer}>
          <p className={styles.FooterSocialLabel}>{T.followUs[locale]}: </p>
          <Link href={`https://www.instagram.com/kayakomat`}>
            <Icon maxWidth="30px" imgSrc="/icons/instagram.svg" />
          </Link>
          <Link href={`https://www.facebook.com/kayakomatsverige`}>
            <Icon maxWidth="30px" imgSrc="/icons/facebook.svg" />
          </Link>
          <Link href={`https://www.linkedin.com/company/kayakomat/`}>
            <Icon maxWidth="30px" imgSrc="/icons/linkedin.svg" />
          </Link>
        </div>
        <div className={styles.FooterCopyrightContainer}>
          <div className={styles.FooterCopyrightText}>
            © KAYAKOMAT 2022. All Rights Reserved.
          </div>
          <div className={styles.FooterTerms}>
            <a href={`/terms`}>{T.terms[locale] || 'terms'}</a>
            <a href={`/privacy`}>{T.privacy[locale] || 'privacy'}</a>
            {isImpressumVisible && <Link href={`/impressum`}>Impressum</Link>}
          </div>
        </div>
      </div>
    </section>
  )
}
