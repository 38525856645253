'use client'
import T from 'translations/v2/reusable/footer'
import Container from '@layout/Container/Container'

import FooterTop from './Top/Top'
import FooterBottom from './Bottom/Bottom'

import styles from './Footer.module.css'
import { useContext } from 'react'
import AppContext from '@/context/appContext'

export default function Footer({
  locale,
  isAboutCMS = false,
  faqFlag = false,
}) {
  const { location } = useContext(AppContext)
  return (
    <section className={styles.Footer}>
      <Container>
        <section className={styles.FooterInner}>
          <FooterTop
            T={T}
            locale={locale}
            isAboutCMS={isAboutCMS}
            faqFlag={faqFlag}
          />
          <FooterBottom
            T={T}
            locale={locale}
            currentCountry={
              location && location?.countryCode?.toLocaleLowerCase()
            }
          />
        </section>
      </Container>
    </section>
  )
}
