import Link from 'next/link'
import { useState } from 'react'

import Icon from '@library/Icons/Icons'
import Button from '@library/Button/Button'
import buttonT from '@/translations/v2/ui/buttons.js'
import footerT from '@/translations/v2/reusable/footer'

import styles from '../Header.module.css'

export default function MobileMenuNav({ locale, page = 'unkown' }) {
  const [menuActive, setMenuActive] = useState(false)

  return (
    <div className={styles.MobileMenu}>
      <div onClick={() => setMenuActive(!menuActive)}>
        <Icon imgSrc="/icons/menu-lines.svg" />
      </div>
      {menuActive && (
        <div
          className={styles.ModalBackground}
          onClick={() => setMenuActive(false)}
        >
          <div
            className={styles.MobileMenuContainer}
            onClick={() => setMenuActive(false)}
          >
            <div className={styles.MobileMenuButton}>
              <Button style="orange" size={'medium'} url={`/${locale}`}>
                {buttonT.findKayakomatButton[locale]}
              </Button>
            </div>
            {page !== 'start' && (
              <div className={styles.MobileMenuButton}>
                <Button size={'medium'} url={`/${locale}/start-kayakomat-franchise`}>
                  {footerT.startKayakomatLabel[locale]}
                </Button>
              </div>
            )}
            <div className={styles.MobileMenuLink}>
              <Link href={`/${locale}/faq`} passHref>
                <div>{footerT.faq[locale]}</div>
              </Link>
            </div>
            <div className={styles.MobileMenuLink}>
              <Link href={`/${locale}/about`} passHref>
                <div>{footerT.about[locale]}</div>
              </Link>
            </div>
            <div className={styles.MobileMenuLink}>
              <Link href={`/${locale}/contact`} passHref>
                <div>{footerT.contact[locale]}</div>
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
