import useSWR from 'swr'

import { getRequest } from '../helpers/httpRequests'
import { useContext } from 'react'
import AppContext from 'context/appContext'

export default function useGetCountries() {
  const { sessionId } = useContext(AppContext)
  const url = `${process.env.NEXT_PUBLIC_APP_API}/web/countries`

  const { data, error, isLoading, mutate } = useSWR(
    { url, sessionId },
    getRequest
  )

  return {
    countriesError: error,
    countriessMutate: mutate,
    countriesData: data?.data,
    countriesIsLoading: isLoading,
  }
}
