module.exports = {
  header: {
    sv: 'COOKIE CONSENT OPTIONS',
    en: 'COOKIE CONSENT OPTIONS',
    de: 'COOKIE CONSENT OPTIONS',
    es: 'COOKIE CONSENT OPTIONS',
    dk: 'COOKIE CONSENT OPTIONS',
    fr: 'COOKIE CONSENT OPTIONS',
  },
  cookieText: {
    sv: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
    en: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
    de: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
    es: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
    dk: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
    fr: 'The options provided in this section allow you to customize your consent preferences for any tracking technology used for the purposes described below. To learn more check our ',
  },
  cookieTextLink: {
    sv: 'privacy policy.',
    en: 'privacy policy.',
    de: 'privacy policy.',
    es: 'privacy policy.',
    dk: 'privacy policy.',
    fr: 'privacy policy.',
  },
  necessary: {
    sv: 'Necessary',
    en: 'Necessary',
    de: 'Necessary',
    es: 'Necessary',
    dk: 'Necessary',
    fr: 'Necessary',
  },
  necessaryText: {
    sv: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
    en: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
    de: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
    es: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
    dk: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
    fr: 'These trackers are used for activities that are strictly necessary to operate or deliver the service you requested from us.',
  },
  preferences: {
    sv: 'Preferences',
    en: 'Preferences',
    de: 'Preferences',
    es: 'Preferences',
    dk: 'Preferences',
    fr: 'Preferences',
  },
  preferencesText: {
    sv: 'These trackers help us to improve the quality of your user experience and enable interactions.',
    en: 'These trackers help us to improve the quality of your user experience and enable interactions.',
    de: 'These trackers help us to improve the quality of your user experience and enable interactions.',
    es: 'These trackers help us to improve the quality of your user experience and enable interactions.',
    dk: 'These trackers help us to improve the quality of your user experience and enable interactions.',
    fr: 'These trackers help us to improve the quality of your user experience and enable interactions.',
  },
  statistics: {
    sv: 'Statistics',
    en: 'Statistics',
    de: 'Statistics',
    es: 'Statistics',
    dk: 'Statistics',
    fr: 'Statistics',
  },
  statisticsText: {
    sv: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    en: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    de: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    es: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    dk: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
    fr: 'These trackers help us to measure traffic and analyze your behavior to improve our service.',
  },
  marketing: {
    sv: 'Marketing',
    en: 'Marketing',
    de: 'Marketing',
    es: 'Marketing',
    dk: 'Marketing',
    fr: 'Marketing',
  },
  marketingText: {
    sv: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    en: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    de: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    es: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    dk: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
    fr: 'These trackers help us to deliver personalized ads or marketing content to you, and to measure their performance.',
  },
  managePreferrences: {
    sv: 'Manage Cookie Preferences',
    en: 'Manage Cookie Preferences',
    de: 'Manage Cookie Preferences',
    es: 'Manage Cookie Preferences',
    dk: 'Manage Cookie Preferences',
    fr: 'Manage Cookie Preferences',
  },
  necessaryOnly: {
    sv: 'Necessary Only',
    en: 'Necessary Only',
    de: 'Necessary Only',
    es: 'Necessary Only',
    dk: 'Necessary Only',
    fr: 'Necessary Only',
  },
  selection: {
    sv: 'Allow Selection',
    en: 'Allow Selection',
    de: 'Allow Selection',
    es: 'Allow Selection',
    dk: 'Allow Selection',
    fr: 'Allow Selection',
  },
  all: {
    sv: 'Allow All',
    en: 'Allow All',
    de: 'Allow All',
    es: 'Allow All',
    dk: 'Allow All',
    fr: 'Allow All',
  },
}
