import { getCookie, setCookie } from 'cookies-next'

export const getSessionId = () => {
  const existingSessionId = getCookie('sessionId')
  if (existingSessionId) {
    return existingSessionId
  } else {
    const timestamp = new Date().getTime().toString()
    const randomString = Math.random().toString(36).slice(2, 6)
    const sessionId = `${timestamp}_${randomString}`
    setCookie('sessionId', sessionId)
    return sessionId
  }
}
