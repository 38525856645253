'use client'

import { useState, useEffect, useCallback, useContext } from 'react'
import styles from './CookiesBanner.module.css'
import cookieTrans from 'translations/cookie'
import H3 from '@library/Texts/H3'
import Button from '@library/Button/Button'
import Checkbox from '@library/Inputs/Checkbox/Checkbox'
import getCookie from 'helper/getCookie'
import AppContext from '@/context/appContext'
import getLocation from '@/helper/ipLocation'

declare const window: Window & { dataLayer: any }

export default function CookieBanner({ locale }) {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const [necessary, setNecessary] = useState('denied')
  const [preferences, setPreferences] = useState('denied')
  const [statistics, setStatistics] = useState('denied')
  const [marketing, setMarketing] = useState('denied')
  const { location, setLocation } = useContext(AppContext)

  const getConsentObject = () => {
    return {
      ad_storage: marketing,
      analytics_storage: statistics,
      ad_user_data: marketing,
      ad_personalization: marketing,
    }
  }

  useEffect(() => {
    if (necessary !== 'granted') {
      setNecessary(getCookie('KAYAKOMAT_COOKIE_CONSENT') || 'denied')
      setPreferences(
        getCookie('KAYAKOMAT_COOKIE_CONSENT_PREFERENCES') || 'denied'
      )
      setStatistics(
        getCookie('KAYAKOMAT_COOKIE_CONSENT_STATISTICS') || 'denied'
      )
      setMarketing(getCookie('KAYAKOMAT_COOKIE_CONSENT_MARKETING') || 'denied')
      sendConsent(getConsentObject(), 'update')
    }
  }, [])

  useEffect(() => {
    const getLocationIP = async () => {
      const locationIP = await getLocation()
      setLocation(locationIP)
      return locationIP
    }

    if (necessary === 'granted') {
      setCookies()
      sendConsent(getConsentObject(), 'update')
      if (!location?.countryCode) {
        getLocationIP()
      }
    }
  }, [necessary, location, setLocation])

  function gtag() {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
  }

  const getExpirationDate = () => {
    const d = new Date()
    d.setTime(d.getTime() + 183 * 24 * 60 * 60 * 1000)
    return d.toUTCString()
  }

  const setCookies = () => {
    const expDate = getExpirationDate()
    document.cookie = `KAYAKOMAT_COOKIE_CONSENT=granted; expires="${expDate}"; path=/`
    document.cookie = `KAYAKOMAT_COOKIE_CONSENT_PREFERENCES=${preferences}; expires="${expDate}"; path=/`
    document.cookie = `KAYAKOMAT_COOKIE_CONSENT_STATISTICS=${statistics}; expires="${expDate}"; path=/`
    document.cookie = `KAYAKOMAT_COOKIE_CONSENT_MARKETING=${marketing}; expires="${expDate}"; path=/`
  }

  const sendConsent = useCallback(
    (consent, mode: 'default' | 'update' = 'default') => {
      // @ts-ignore
      gtag('consent', mode, consent)
    },
    []
  )

  const handleNecessaryOnly = () => {
    setPreferences('denied')
    setStatistics('denied')
    setMarketing('denied')
    setNecessary('granted')
  }

  const handleAllowSelection = async () => {
    setNecessary('granted')
  }

  const handleAllowAll = async () => {
    setPreferences('granted')
    setStatistics('granted')
    setMarketing('granted')
    setNecessary('granted')
  }

  const handleManagePreferences = () => {
    setIsCollapsed(false)
  }

  return (
    necessary !== 'granted' && (
      <section className={styles.CookiesBanner}>
        <div className={styles.headerWrapper}>
          <div className={styles.header}>
            <H3>{cookieTrans.header[locale]}</H3>
          </div>
          <div className={styles.CookiesBannerText}>
            {cookieTrans.cookieText[locale]}
            <a
              className={styles.CookiesBannerTextLink}
              href="https://www.kayakomat.com/privacy"
            >
              {cookieTrans.cookieTextLink[locale]}
            </a>
          </div>
        </div>
        {!isCollapsed && (
          <div className={styles.togglesWrapper}>
            <div>
              <Checkbox
                label={cookieTrans.necessary[locale]}
                value={true}
                disabled
              />
              <span>{cookieTrans.necessaryText[locale]}</span>
            </div>
            <div>
              <Checkbox
                label={cookieTrans.preferences[locale]}
                value={preferences === 'granted' ? true : false}
                onChange={(isSelected: boolean) =>
                  setPreferences(isSelected ? 'granted' : 'denied')
                }
              />
              <span>{cookieTrans.preferencesText[locale]}</span>
            </div>
            <div>
              <Checkbox
                label={cookieTrans.statistics[locale]}
                value={statistics === 'granted' ? true : false}
                onChange={(isSelected: boolean) =>
                  setStatistics(isSelected ? 'granted' : 'denied')
                }
              />
              <span>{cookieTrans.statisticsText[locale]}</span>
            </div>
            <div>
              <Checkbox
                label={cookieTrans.marketing[locale]}
                value={marketing === 'granted' ? true : false}
                onChange={(isSelected: boolean) =>
                  setMarketing(isSelected ? 'granted' : 'denied')
                }
              />
              <span>{cookieTrans.marketingText[locale]}</span>
            </div>
          </div>
        )}
        <div className={styles.buttonsWrapper}>
          <Button size={'small'} onClick={handleAllowAll} style="cookie">
            {cookieTrans.all[locale]}
          </Button>
          {isCollapsed ? (
            <Button size={'small'} onClick={handleManagePreferences}>
              {cookieTrans.managePreferrences[locale]}
            </Button>
          ) : (
            <>
              <Button size={'small'} onClick={handleAllowSelection}>
                {cookieTrans.selection[locale]}
              </Button>
              <Button size={'small'} onClick={handleNecessaryOnly}>
                {cookieTrans.necessaryOnly[locale]}
              </Button>
            </>
          )}
        </div>
      </section>
    )
  )
}
