import Image from 'next/image'
import styles from './Icons.module.css'

type IconTypes = {
  imgSrc: string
  alt?: string
  vAlign?: 'center' | 'start' | 'end'
  hAlign?: 'center' | 'start' | 'end'
  maxWidth?: string
}

export default function Icon({
  imgSrc,
  alt = 'test',
  vAlign,
  hAlign,
  maxWidth = '100px',
}: IconTypes) {
  let className = styles.Icon
  if (vAlign) {
    className += ` ${styles[`v-align--${vAlign}`]}`
  }
  if (hAlign) {
    className += ` ${styles[`h-align--${hAlign}`]}`
  }
  return (
    <div className={className}>
      {/* <img src={imgSrc} style={{ maxWidth }} /> */}
      {imgSrc && (
        <Image
          className={`${styles.Image} ${styles.BorderRadius}`}
          src={imgSrc && imgSrc}
          alt={alt}
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: '100%', height: 'auto', maxWidth }} // optional
        />
      )}
    </div>
  )
}
